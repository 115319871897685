export default {
  //未来之光APP下载
  downloadAPP() {
    // 判断是Android还是iOS
    const u = navigator.userAgent;
    let system = "";
    if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      system = "Android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      system = "iOS";
    } else {
      system = "unknown";
    }
    // 根据系统不同，跳转不同的下载APP地址
    if (system === "Android") {
      window.location = "https://oss.eletell.com/wlzg/apk/lighten_keep.apk";
    } else if (system === "iOS") {
      window.location =
        "https://itunes.apple.com/cn/app/%E6%9C%AA%E6%9D%A5%E4%B9%8B%E5%85%89/id1416993897?mt=8";
    } else {
      window.location = "https://oss.eletell.com/wlzg/apk/lighten_keep.apk";
    }
  },
   //未来之光分销员APP下载
   downloadDistributorAPP() {
    // 判断是Android还是iOS
    const u = navigator.userAgent;
    let system = "";
    if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      system = "Android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      system = "iOS";
    } else {
      system = "unknown";
    }
    // 根据系统不同，跳转不同的下载APP地址
    if (system === "Android") {
      window.location = "https://oss.eletell.com/wlzg/apk/adc/lighten_wlzg-partner.apk";
    } else if (system === "iOS") {
      window.location =
        "https://itunes.apple.com/cn/app/%E6%9C%AA%E6%9D%A5%E4%B9%8B%E5%85%89/id1416993897?mt=8";
    } else {
      window.location = "https://oss.eletell.com/wlzg/apk/adc/lighten_wlzg-partner.apk";
    }
  },
  // 倍速阅读APP下载
  downloadBsydApp() {
    // 判断是Android还是iOS
    const u = navigator.userAgent;
    let system = "";
    if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      system = "Android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      system = "iOS";
    } else {
      system = "unknown";
    }
    // 根据系统不同，跳转不同的下载APP地址
    if (system === "Android") {
      window.location =
        "https://oss.eletell.com/lighten_lecture/apk/lecture_own.apk";
    } else if (system === "iOS") {
      window.location =
        "https://apps.apple.com/cn/app/%E5%80%8D%E9%80%9F%E9%98%85%E8%AF%BB/id1538908266";
    } else {
      window.location =
        "https://oss.eletell.com/lighten_lecture/apk/lecture_own.apk";
    }
  },
  // 小象脑力APP下载
  downloadXxnlApp() {
    // 判断是Android还是iOS
    const u = navigator.userAgent;
    let system = "";
    if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      system = "Android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      system = "iOS";
    } else {
      system = "unknown";
    }
    // 根据系统不同，跳转不同的下载APP地址
    if (system === "Android") {
      window.location = "https://oss.eletell.com/light_child/apk/loc_own.apk";
    } else if (system === "iOS") {
      window.location =
        "https://apps.apple.com/cn/app/%E6%9C%AA%E6%9D%A5%E4%B9%8B%E5%85%89-%E5%84%BF%E7%AB%A5/id1499779043";
    } else {
      window.location = "https://oss.eletell.com/light_child/apk/loc_own.apk";
    }
  },
  // 思维旅行APP下载
  downloadSwlxApp() {
    // 判断是Android还是iOS
    const u = navigator.userAgent;
    let system = "";
    if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      system = "Android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      system = "iOS";
    } else {
      system = "unknown";
    }
    // 根据系统不同，跳转不同的下载APP地址
    if (system === "Android") {
      window.location =
        "https://oss.eletell.com/lighten_totravel/apk/lighten_own.apk";
    } else if (system === "iOS") {
      window.location =
        "https://apps.apple.com/cn/app/%E6%80%9D%E7%BB%B4%E6%97%85%E8%A1%8C/id1523610838";
    } else {
      window.location =
        "https://oss.eletell.com/lighten_totravel/apk/lighten_own.apk";
    }
  },

  //获取用户访问量
  Useraccess(pageId) {
    ApiService.getPageInterview({
      pageId: pageId,
    }).then((res) => {});
  },
};
